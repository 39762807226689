$scrollbar-track-width: 12px;
$scrollbar-thumb-width: 8px;
$scrollbar-thumb-height: 24px;
$scrollbar-track-color: #fff;
$scrollbar-thumb-color: #6E6E6E;
$scrollbar-thumb-border-color: #c9c9c9;

[data-citroen-modules="scrollbar"] {
  width: 100%;
  position: relative;
}

[data-citroen-modules="scrollbar"] .viewport {
  height: 300px;
  overflow:hidden;
  position:relative;
  margin-right: ($scrollbar-track-width * 2);
  padding-bottom: $content-gutter;
}

[data-citroen-modules="scrollbar"] .overview {
  list-style:none;
  position:absolute;
  left: 0;
  top: 0;
  right: 0;
}

[data-citroen-modules="scrollbar"] .scrollbar {
  // background:transparent url(images/bg-scrollbar-track-y.png) no-repeat 0 0;
  position:absolute;
  background-position:0 0;
  right: 5px;
  top: 0;
  width: $scrollbar-track-width;
}

[data-citroen-modules="scrollbar"] .track {
  height:100%;
  width: $scrollbar-track-width;
  position:relative;
  padding:0 1px;
  background-color: $scrollbar-track-color;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQYV2M4efLkfwAIFQNbshpzWQAAAABJRU5ErkJggg==');
  background-repeat: repeat-y;
  background-position: center top;
  background-size: 2px auto;
}

[data-citroen-modules="scrollbar"] .thumb {
    // background:transparent url(images/bg-scrollbar-thumb-y.png) no-repeat 50% 100%;
  cursor:pointer;
  overflow:hidden;
  position:absolute;
  top: 0;
  left: 2px;
  display: block;
  height: $scrollbar-thumb-height;
  width: $scrollbar-thumb-width;
  background-color: $scrollbar-thumb-color;
  border-top: 2px solid $scrollbar-thumb-border-color;

  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 50%;
    border: {
      bottom: 6px solid #fff;
      left: 2px solid transparent;
      right: 2px solid transparent;
    }
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

[data-citroen-modules="scrollbar"] .thumb .end {
  // background:transparent url(images/bg-scrollbar-thumb-y.png) no-repeat 50% 0;
  overflow:hidden;
  height: $scrollbar-thumb-height;
  width: $scrollbar-thumb-width;
}

[data-citroen-modules="scrollbar"] .disable {
  display:none;
}

.noSelect {
  user-select:none;
  -o-user-select:none;
  -moz-user-select:none;
  -khtml-user-select:none;
  -webkit-user-select:none;
}
