$content-font: 'robotoregular', sans-serif;
$content-button-font: 'robotomedium', sans-serif;
$content-header-font: 'DSTitleWebMedium-Regular', serif;

$content-theme-primary-color: #AD9D91; // maron
$content-theme-secondary-color: #1d1717; // dark maron

// logo
$content-nextgen-logo: '../images/nextgen-logo.png';

$content-font-size: 12px;
$content-font-color: #646464;
$content-background: $content-theme-secondary-color;

// content header
$content-header-color: #fff;
$content-header-text-transform: uppercase;
$content-header-size: 24px;
$content-header-padding: 0;
$content-header-border: none;
// introduction font
$content-introduction-font-color: #fff;

$content-section-font-color: #000;

// button
$content-button-font-size: 13px;
$content-button-font-color: #ad9d91;
$content-button-border-color: $content-button-font-color;
$content-button-background-color: $content-theme-secondary-color;
$content-button-font-color-overstate: $content-theme-secondary-color;
$content-button-background-color-overstate: $content-button-font-color;
$content-button-text-align: center;
$content-button-text-indent: 0;
// button arrow
$content-button-arrow-content: '';
$content-button-arrow-width: 1em;
$content-button-arrow-height: 1em;
$content-button-arrow: none;
$content-button-arrow-overstate: none;

// breadcrumb
$breadcrumb-width: 113px;
$breadcrumb-height: 30px;
$breadcrumb-gutter: 7px;
$breadcrumb-font-size: 12px;
$breadcrumb-font-color: $content-theme-primary-color;
$breadcrumb-font-color-overstate: $content-button-font-color-overstate;
$breadcrumb-border-size: 1px;
$breadcrumb-border-style: solid;
$breadcrumb-border-color: $breadcrumb-font-color;
$breadcrumb-background-color: $content-background;
$breadcrumb-background-color-overstate: $content-theme-primary-color;
// breadcrumb back
$breadcrumb-content-back: '\0003C';
$breadcrumb-content-back-right-position: 4.5em;
$breadcrumb-content-back-background-image: '';

// dropdown
$dropdown-width: 130px;
$dropdown-item-height: 2.8em;
$dropdown-pad: 10px;
$dropdown-font-color: #000;
$dropdown-heading-color: #696969;
$dropdown-button-border: #ccc;
$dropdown-button-background: #dedede;
$dropdown-button-height: 24px;
$dropdown-button-arrow-content: '\025be';
$dropdown-button-arrow: none;
$dropdown-button-arrow-size: 9px 9px;
$dropdown-button-background-overstate: $content-theme-primary-color;

// figcaption
$content-figcaption-font-color: #646464;
$content-carousel-header-font-color: $content-section-font-color;
$content-carousel-header-font-size: $content-font-size;

// content navigation
$content-nav-font-color: #000;
$content-nav-background-color: #FFFFFF;
$content-nav-border-color: #8f8f8f;
$content-nav-arrow-color: #AD0040;
$content-nav-figure-background: url('../images/nav-background.png') no-repeat center center;
$content-nave-active-border: 0;

// information content
$information-content-gradient-top: #E6E4E4;
$information-content-gradient-bottom: #fff;
$information-content-gradient-background-fallback: #fff;
$information-content-gradient-background-webkit: -webkit-linear-gradient(top, $information-content-gradient-top 30%, $information-content-gradient-bottom 70%);
$information-content-gradient-background: linear-gradient(to bottom, $information-content-gradient-top 30%, $information-content-gradient-bottom 70%);
// carousel phone slightly different gradient
$carousel-padding: 0 ($content-gutter * 2);
$carousel-gradient-bottom: #FBFBFB;
$carousel-gradient-background-fallback: #FBFBFB;
$carousel-gradient-background-webkit: -webkit-linear-gradient(top, $information-content-gradient-top 0%, $carousel-gradient-bottom 45%);
$carousel-gradient-background: linear-gradient(to bottom, $information-content-gradient-top 0%, $carousel-gradient-bottom 45%);
// carousel system
$carousel-system-padding: ($content-gutter / 2) ($content-gutter * 2);

// frame center
$frame-center-border: 1px solid red;
$frame-center-box-shadow: 0 1px 3px rgba(34, 25, 25, 0.4);

// table
$stripes-odd-color: #EDEDED;

// tab
$tab-item-gutter: 7px;
$tab-font-size: 12px;
$tab-section-font-color: #696969;
$tab-border-top: none;
$tab-background-color: #FBFBFB;
$tab-active-font-color:  $content-section-font-color;
$tab-active-font-weight: 400;
$tab-active-background-color: $content-theme-primary-color;
$tab-content-border-top: 2px solid $stripes-odd-color;

// features
$features-system-version-background-color: #ACD6FF;

// tab content
$tab-content-background-color: #fff;
$tab-content-heading-size: 14px;
$tab-content-heading-color: #000;

// system description
$system-description-gradient-top: #B8B8B8;
$system-description-gradient-bottom: #DDDCDC;
$system-description-gradient-background-fallback: #DDDCDC;
$system-description-gradient-background-webkit: -webkit-linear-gradient(top, $system-description-gradient-top, $system-description-gradient-bottom);
$system-description-gradient-background: linear-gradient(to bottom, $system-description-gradient-top, $system-description-gradient-bottom);
$system-description-list-arrow-display: inline-block;
$system-description-list-arrow-color: #E62339;

// content nav system version help
$content-nav-system-version-help-size: 24px;
$content-nav-system-version-help-color: $content-theme-primary-color;
$content-nav-system-version-help-border-width: 2px;
$content-nav-system-version-help-border-color: $content-theme-primary-color;

// carousel
$carousel-nav-arrow-size: 10px;
$carousel-nav-arrow-color: #282859;

// modal window
$modal-content-font-color: #646464;
$modal-content-backgorund-color: #fff;
