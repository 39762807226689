// $carousel-nav-width: 20px;
// $carousel-nav-height: 20px;
// citroen style
.owl-nav {
  .owl-prev,
  .owl-next {
    position: absolute;
    top: 23%;
    display: block;
    padding: ($content-gutter /2);
    // width: $carousel-nav-width;
    // height: $carousel-nav-height;
    font-size: 0;

    &:before {
      content: '';
      display: inline-block;
      border: {
        top: ($carousel-nav-arrow-size / 2) solid transparent;
        bottom: ($carousel-nav-arrow-size / 2) solid transparent;
      }
      vertical-align: middle;
      opacity: 1;
    }
    &.disabled {
      &:before {
        display: none;
        .citroen-carousel-five-cars & {
          display: block;
        }
      }
    }
  }

  .owl-prev {
    left: -30px;
    // background: url('../images/icon-carousel-prev.svg') no-repeat 0 0;
    &:before {
      border-right: $carousel-nav-arrow-size solid $carousel-nav-arrow-color;
    }
  }
  .owl-next {
    right: -30px;
    // background: url('../images/icon-carousel-next.svg') no-repeat 0 0;
    &:before {
      border-left: $carousel-nav-arrow-size solid $carousel-nav-arrow-color;
    }
  }
}

.citroen-carousel-five-cars .owl-nav.disabled,
.citroen-carousel-five-cars .owl-dots.disabled {
  display: block !important;
}
